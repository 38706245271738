import axios, { baseURL } from "@axios";
import { paginateArray, sortCompare } from "@/common";
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/invoice/invoices", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/invoice/clients")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchOpenbills(ctx, queryParams) {
      const {
        q = "",
        perPage = 10,
        page = 1,
        sortBy = "id",
        sortDesc = false,
        status = null,
        date = null,
        search = [],
        time = null,
        dateTo = null
      } = queryParams;

      var dateFrom = null
      if(date) {
        dateFrom = new Date(date);
      }
      var dateTos = null
      if(dateTo) {
        dateTos = new Date(dateTo);
      }
      // if (date && date.length > 11) {
      //   dateFrom = new Date(date.substring(0, 10));
      //   dateTo = new Date(date.substring(14, 24));
      //   if (time) {
      //     dateFrom = new Date(date.substring(0, 4),Number(date.substring(5, 7)) - 1,date.substring(8, 10), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
      //     dateTo = new Date(date.substring(14, 18),Number(date.substring(19, 21)) - 1,date.substring(22, 24), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
      //   }
      // } else if (date && date.length <= 11) {
      //   dateFrom = new Date(date.substring(0, 10));
      //   if (time) {
      //     dateFrom = new Date(date.substring(0, 4),Number(date.substring(5, 7)) - 1,date.substring(8, 10), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
      //   }
      // }

      var userSearch = []
      search.forEach(item => {
          userSearch.push(
            item.label
          )
      }); 

      const payload = {
        key: q,
        dateFrom: dateFrom,
        dateTo: dateTos,
        status: status,
        userList: userSearch
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/GetOrderByStatusOpenBill`, payload)
          .then((response) => {
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.totalCount,
            });
          })
          .catch((error) => reject(error));
      });
    },
    fetchOpenbill(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/GetOrderOpenBillById?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/Update?orderId=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserList(ctx) {
      return new Promise((resolve, reject) => {
        const payload = {
          key: "",
          dateFrom: null,
          dateTo: null,
          status: null
        }
        axios
          .post(`${baseURL}/api/User/GetByKey`, payload)
          .then((response) => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
};
